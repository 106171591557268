import 'jquery';

class Tabs {
	constructor() {
		this.initialize();
	}

	initialize() {
		const tabsToggle = function() {
			let tab = $('.tabs__tab');

			tab.on('click', function() {
				let el = $(this);
				let tabAttr = el.attr('data-tab');
				let content = $(`.tabs__window[data-content="${tabAttr}"]`);

				tab.removeClass('active');
				el.addClass('active');
				$('.tabs__window').removeClass('active');
				content.addClass('active');
			});
		};

		tabsToggle();
	}
}

new Tabs();
