class catalog {
    constructor() {
        this.initialize();
    }

    initialize() {
        const counter = document.querySelectorAll('.js-quantity-counter');
        const input = document.querySelector('.js-quantity-counter input');

        if (!counter) return false;
        if (!input) return false;

        counter.forEach(function (elem) {
            elem.addEventListener('click', function (evt) {
                evt.stopPropagation();
                const target = evt.target;
                const buttonMinus = target.closest('.basket__quantity-minus');
                const buttonPlus = target.closest('.basket__quantity-plus');

                if (buttonPlus) {
                    let input = this.closest('.js-quantity-counter').querySelector('input');
                    let inputValue = +input.value;

                    // let dataOldPrice = +this.closest('.basket__row-inner').querySelector('.basket__price-old').dataset.oldprice.replace(/[^+\d]/g, '');
                    // let dataPrice = +this.closest('.basket__row-inner').querySelector('.basket__price-now').dataset.price.replace(/[^+\d]/g, '');

                    // let priceContent = +this.closest('.basket__row-inner').querySelector('.basket__price-now').textContent.replace(/[^+\d]/g, '');
                    // let priceOldContent = +this.closest('.basket__row-inner').querySelector('.basket__price-old').textContent.replace(/[^+\d]/g, '');

                    inputValue = inputValue + 1;
                    input.value = inputValue;

                    if (inputValue >= 99) {
                        input.value = 99;
                        inputValue = 99;
                    }

                    // priceOldContent = priceOldContent + dataOldPrice;
                    // priceOldContent = this.closest('.basket__row-inner').querySelector('.basket__price-old').textContent = priceOldContent;

                    // priceContent = priceContent + dataPrice;
                    // priceContent = this.closest('.basket__row-inner').querySelector('.basket__price-now').textContent = priceContent;
                    if ($('input[name=BasketRefresh]').length > 0) {
                        $('input[name=BasketRefresh]').click();
                    }
                } else if (buttonMinus) {
                    let input = this.closest('.js-quantity-counter').querySelector('input');
                    let inputValue = +input.value;

                    // let dataOldPrice = +this.closest('.basket__row-inner').querySelector('.basket__price-old').dataset.oldprice.replace(/[^+\d]/g, '');
                    // let dataPrice = +this.closest('.basket__row-inner').querySelector('.basket__price-now').dataset.price.replace(/[^+\d]/g, '');

                    // let priceContent = +this.closest('.basket__row-inner').querySelector('.basket__price-now').textContent.replace(/[^+\d]/g, '');
                    // let priceOldContent = +this.closest('.basket__row-inner').querySelector('.basket__price-old').textContent.replace(/[^+\d]/g, '');

                    inputValue = inputValue - 1;
                    input.value = inputValue;

                    if (inputValue < 1) {
                        input.value = 0;
                        inputValue = 0;
                    }

                    // priceOldContent = priceOldContent - dataOldPrice;
                    // priceOldContent = this.closest('.basket__row-inner').querySelector('.basket__price-old').textContent = priceOldContent;

                    // priceContent = priceContent - dataPrice;
                    // priceContent = this.closest('.basket__row-inner').querySelector('.basket__price-now').textContent = priceContent;
                    if ($('input[name=BasketRefresh]').length > 0) {
                        $('input[name=BasketRefresh]').click();
                    }
                }
            });
        });

        input.addEventListener('click', function (evt) {
            evt.stopPropagation();
        });
    }
}

new catalog();
