import 'jquery';
import Swiper from 'swiper/bundle';

class SliderBrandName {
	constructor() {
		this.initialize();
	}

	initialize() {
		const tickerSliderMainText = new Swiper('[data-slider-brand-name]', {
			direction: 'vertical',
			slidesPerView: 'auto',
			loopedSlides: 1,
			spaceBetween: 30,
			loop: true,
			speed: 15000,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
				reverseDirection: true
			}
		});

		const tickerSliderMainTextMobile = new Swiper('[data-slider-brand-name-mob]', {
				direction: 'vertical',
				slidesPerView: 'auto',
				loop: true,
				speed: 15000,
				autoplay: {
					delay: 0,
					disableOnInteraction: false,
					reverseDirection: true
				}
			}
		);

		function setHeightImg() {
			let ww = $(window).width();

			if (ww < 1024) {
				let imgHeigth = $('.main-section__right-img img').height();
				let brandName = $('[data-slider-brand-name-mob]');

				// brandName.css('height', `${imgHeigth}`);
			}
		}

        setHeightImg();

		$(window).on('load', setHeightImg);
		$(window).on('resize', setHeightImg);
	}
}

new SliderBrandName();
