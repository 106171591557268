import 'jquery';

class Accordeon {

    constructor() {
        this.initialize();
    }

    initialize() {
        const accordeonMenu = function () {
            $('.js-toggle-accordeon-btn').on('click', function () {
                const target = $(this);

                target.toggleClass('close');
                $('.js-toggle-accordeon-btn').not(this).addClass('close');

                setTimeout(function () {
                    const top = target.offset().top;
                    $('html, body').animate({ scrollTop: top + 'px' }, 500);
                }, 100);
            });
        }
        accordeonMenu();
    }
}

new Accordeon();