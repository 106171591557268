import 'jquery';

class Card {

    constructor() {
        this.initialize();
    }

    initialize() {
        const likeAndViewToggle = function () {
            $('.js-like-toggle').on('click', function (evt) {
                evt.preventDefault();

                const target = $(this);

                target.toggleClass('active')
            });
        }

        likeAndViewToggle();
    }
}

new Card();


