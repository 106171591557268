import 'jquery';
import Swiper from 'swiper/bundle';

export class SliderTags {
	constructor() {
		this.initialize();
	}

	initialize() {
		const tagsSlider = new Swiper('[data-tags-slider]', {
			slidesPerView: 'auto',
			spaceBetween: 30,
		});
	}
}

new SliderTags();
