import 'jquery';
require("magnific-popup");
// import "sumoselect";

global.jQuery = $;
global.jquery = $;
global.$ = $;

import svg4everybody from 'svg4everybody/dist/svg4everybody';
import objectFitImages from 'object-fit-images';
import LazyLoad from 'lazyload/lazyload';
import Swiper from 'swiper/bundle';
import MagnificPopup from 'magnific-popup/dist/jquery.magnific-popup';
import SumoSelect from 'sumoselect/jquery.sumoselect';

import '../scss/common.scss';

import '../components/basket/basket';
import '../components/toggle-grid/toggle-grid';
import '../components/categories/categories';
import '../components/filter/filter';
import '../components/card/card';
import '../components/accordeon/accordeon';
import '../components/tabs/tabs';
import '../components/droplist/droplist';
import '../components/subscribe/subscribe';
import '../components/ordering/ordering';
import '../components/search/search';
import '../components/menu-mobile/menu-mobile';
import '../components/catalog/catalog';
import '../components/slider-blog/slider-blog';
import '../components/slider-brand-name/slider-brand-name';
import '../components/slider-brands/slider-brands';
import '../components/slider-card/slider-card';
import '../components/slider-preview-product/slider-preview-product';
import '../components/slider-gallery/slider-gallery';
import '../components/slider-tags/slider-tags';
import '../components/slider-article-tags/slider-article-tags';
import '../components/slider-ticker/slider-ticker';
import '../components/slider-compare/slider-compare';
import '../components/counter/counter';
import '../components/reg-user/reg-user';
import '../components/short-modal/short-modal';
import '../components/backend/backend';
import '../components/menu-carousel/menu-carousel';
import '../components/faq/faq';
// import '../components/photozoom/photozoom';

$(document).ready(function () {
	// adds SVG External Content support to all browsers
	svg4everybody();

	// Polyfill object-fit/object-position on <img>
	objectFitImages();

	// MagnificPopup
	function popupInit() {
		$('[data-mfp-src]').each(function () {
			let id = $(this).data('mfpSrc');

			$('[data-mfp-src="' + id + '"]').magnificPopup({
				callbacks: {
					open: function () {
						$('body').addClass('no-scroll');
						if (id === '#ordering-popup-modal'){

							const isIOS = (function () {
								let iosQuirkPresent = function () {
									let audio = new Audio();
									audio.volume = 0.5;
									return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
								};
								let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
								let isAppleDevice = navigator.userAgent.includes('Macintosh');
								let isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)
								return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
							})();

							if (isIOS && $(window).width() <= 768){
								const nextButton = $('.js-ordering-btn');
								if (nextButton.length){
									nextButton.css('margin-bottom','60px');
								}
							}
						}
					},
					close: function () {
						$('body').removeClass('no-scroll');
					}
				},
				closeOnBgClick: id !== '#personal-area-popup'
			});
		});
	}
	popupInit();

	$('[data-zoom-photo]').magnificPopup({
		type: 'image',
		tLoading: 'Загрузка...',
		gallery: {
			enabled: !0,
			navigateByImgClick: !0,
			preload: [0, 1],
			tCounter: '%curr% из %total%'
		},
	});

	// lazyload
	let images = document.querySelectorAll('img.lazyload');
	new LazyLoad(images);

	window.LazyLoad = LazyLoad;

	$('.custom-select').SumoSelect();


	if($('.catalog__inner').find('.catalog__filter-items').children().length === 0){
		$('.catalog__inner').find('.catalog__filter-items').addClass('hidden')
	}

	// Popup initialisation
	function showPopup(_this, isFixed) {
		isFixed = isFixed || false;

		$('body').append(_this);
		var _windowHeight = $(window).height();
		var _windowWidth = $(window).width();

		var _popupHeight = _this.outerHeight();
		var _popupWidth = _this.outerWidth();

		var _scrolltop = $(window).scrollTop();
		var _popupTop = 0;
		var _popupLeft = 0;

		if (_popupHeight < _windowHeight) {
			if (isFixed) {
				_popupTop = parseInt((_windowHeight - _popupHeight) / 2);
			} else {
				_popupTop = parseInt(_scrolltop + (_windowHeight - _popupHeight) / 2);
			}
		} else {
			if (!isFixed) {
				_popupTop = _scrolltop;
			}
		}

		if (_popupWidth < _windowWidth) {
			_popupLeft = parseInt((_windowWidth - _popupWidth) / 2);
		}

		_this.css({
			"margin-top": "0",
			"margin-left": "0",
			"top": _popupTop,
			"left": _popupLeft
		});

		if (isFixed) {
			_this.css({
				"position": "fixed"
			});
		}

		$(".window-fill").show();
		$(_this).show();
	}

	$('.card-product__size-link').on("click", function () {
        _popup = $('.pop-up.table_size');
        showPopup(_popup);
        return false;
    });

	$(".pop-up .close, .window-fill").on('click', function () {
        $(".window-fill, .pop-up").hide();
    });

	$(function(){
		let userDeviceArray = [
			{device: 'Macintosh', platform: /Macintosh/}
		];
		let platform = navigator.userAgent;

		function getPlatform() {
			for (let i in userDeviceArray) {
				if (userDeviceArray[i].platform.test(platform)) {
					return userDeviceArray[i].device;
				}
			}
		}

		if (getPlatform() === 'Macintosh') {
			const heading = $('.blog-detail .blog-detail__other-articles .other-articles__left .other-articles__text');
			heading.addClass('mac');
		} else if (getPlatform() === undefined) {
			return false;
		}
	});

});

$(window).on('load', function () {
	$('body').addClass('loaded');

	setTimeout(() => {
		let img = document.querySelectorAll("img.load");
		let lazy = new LazyLoad(img);
		lazy.loadImages();
	}, 1000);

	setHeight();

	$(window).on('scroll resize', setHeight);

	function setHeight() {
		$('html').css({ '--app-height': `${$(window).height()}px` });
	}

	Marquee3k.init()

	$('.filter__button_delete').click(function (){
		let el = this;
		let value = $(el).text();
		let button = $(el);
		$(".filter__dropitem-name").each(function () {
			if ($(this).text() === value) {
				$(this).parent().children("input").removeAttr('checked');
				$(".filter__apply").click();
			}
		});

		let valueStyle = $(el).children('span').attr('style').replace(/\s+/g, '');
		$(".color-picker__label").children(".color-picker__color").each(function () {
			if ($(this).attr('style').replace(/\s+/g, '') === valueStyle) {
				$(this).parent().children("input").removeAttr('checked');
				$(".filter__apply").click();
			}
		});
	});

	$(".filter-popup__form .filter__apply").on('click', function() {
		$(this).addClass('loading');
	});

	$('.filter__button_reset').click(function (){
		let el = this;
		let button = $(el);

		$('.filter__dropitem-name').each(function(){
			$(this).parent().children("input").removeAttr('checked');

		});
		$(".color-picker__label").children(".color-picker__color").each(function () {
			$(this).parent().children("input").removeAttr('checked');
		});
		$(".filter__apply").click();
	});

	function initReviews() {
		const rewiews = $('.detail-card-product__reviews .reviews');

		if (rewiews.length <= 0) {
			$('.card-product-reviews__selected-sort').addClass('disabled');
		}
	}

	function showBrandDescr() {
		const ww = $(window).width();

		if (ww < 1024) {
			const descrBrand = $('[data-brand] [data-brand-descr]');
			let heightDescrBrand = descrBrand.height();
			const btn = `
				<button class="link brand__btn" type="button" data-btn-more>Читать полностью</button>
			`
			function showMore() {
				$('[data-btn-more]').on('click', function () {
					const el = $(this);
					const descrBox = el.parent().find('[data-brand-descr]');

					if (descrBox.hasClass('hide')) {
						descrBox.removeClass('hide');
						$(this).text('Скрыть');
					} else {
						descrBox.addClass('hide');
						$(this).text('Читать полностью');
					}
				});
			}

			if (heightDescrBrand > 115) {
				descrBrand.addClass('hide');

				if ($('[data-btn-more]').length === 0) {
					descrBrand.parent().append(btn);
					showMore();
				}
			}
		}
	}

	initReviews();
	showBrandDescr();
});



