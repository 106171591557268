import Swiper from 'swiper/bundle';

class SliderCompare {
	constructor() {
		this.initialize();
	}

	initialize() {
		const sliderCompare = new Swiper('[data-slider-compare]', {
			slidesPerView: 'auto',
			speed: 600,
			resistanceRatio: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
}

new SliderCompare();
