import 'jquery';
import Swiper from 'swiper/bundle';

class SliderTicker {
	constructor() {
		this.initialize();
	}

	initialize() {
		const tickerSlider = new Swiper('[data-slider-ticker]', {
			slidesPerView: 1,
			loop: true,
			freeMode: true,
			speed: 20000,
			autoplay: {
				delay: 0,
				disableOnInteraction: false
			}
		});
	}
}

new SliderTicker();
