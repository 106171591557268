import 'jquery';

class Filter {

    constructor() {
        this.initialize();
    }

    initialize() {
        // const openPopup = function () {
        //     $('.js-filter-popup').on('click', function () {
            
        //     });
        // }

        const filterMobile = function () {
            $('.js-toggle-filter-mobile').on('click', function () {
                const target = $(this);

                target.toggleClass('active');
            });
        }




        // openPopup();
        filterMobile();
    }
}

new Filter();