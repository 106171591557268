import 'jquery';

class Droplist {
	constructor() {
		this.initialize();
	}

	initialize() {
		$('[data-droplist]').on('click', function() {
			$(this).toggleClass('open');
		});

		$('[data-droplist-body] ul li').on('click', function() {
			let selected = $(this).text();

			$('[data-droplist-head]').text(selected);
		});

		$(document).on('click', function(evt) {
			let target = evt.target;

			if (!target.closest('[data-droplist]')) {
				$('[data-droplist]').removeClass('open');
			}
		});
	}
}

new Droplist();
