import 'jquery';

class Faq {

    constructor() {
        this.initialize();
    }

    initialize() {
        const faq = $('.faq-slide');
        const faqHead = faq.find('.faq-slide__head');

        faqHead.on('click', function() {
            const el = $(this);
            const faqBody = el.parent().find('.faq-slide__body');

            el.parent().toggleClass('active');
            faqBody.toggleClass('hide');
        });
        
    }
}

new Faq();