import 'jquery';
import SumoSelect from 'sumoselect/jquery.sumoselect';

$(document).ready(function () {

    /*добавление в избранное*/
    $('.card__like').on('click', function () {
        const $current = $(this);
        let favorID = $current.attr('data-goods-id');
        let checkedColor = $('input[name=color]:checked').val();

        $.ajax({
            url: '/local/templates/divers-new/ajax/favorites.php', // URL отправки запроса
            type: "POST",
            data: {
                "ID_GOODS": favorID,
                "ID_OFFER_COLOR": checkedColor
            },
            success: function (response) {
                if (response.length > 0) {
                    let result = $.parseJSON(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) { // Если ошибка, то выводим в консоль
                console.log('Error: ' + errorThrown);
            }
        });

        return false;
    });

    /*удаление из списка избранного*/
    $('.del-wish-list').on('click', function () {
        const $current = $(this);
        let favorID = $current.attr('data-goods-id');

        $.ajax({
            url: '/local/templates/divers-new/ajax/favorites.php', // URL отправки запроса
            type: "POST",
            data: {"ID_GOODS": favorID, "WISH_LIST": true},
            success: function (response) {
                if (response.length > 0) {
                    let result = $.parseJSON(response);
                    if (result.CART_RELOAD == 'Y') {
                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_BASKET: 'Y'},
                            success: function (data) {
                                $('#tabFavorites').replaceWith(data);
                            }
                        });
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) { // Если ошибка, то выводим в консоль
                console.log('Error: ' + errorThrown);
            }
        });

        return false;
    });

    /* фиксация выбора способа просмотра каталога (список\плитка) */

    if (BX.getCookie('catalogView') == undefined) {
        var date = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
        document.cookie = 'catalogView=bar; path=/; expires=' + date.toUTCString();
    }

    /*$('.choose-view-blog').on('click', function () {
        var $catalogView = $(this).data('view');

        if ($catalogView == 'bar') {
            var date = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
            document.cookie = 'catalogView=bar; path=/; expires=' + date.toUTCString();
        }
        if ($catalogView == 'list') {
            var date = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
            document.cookie = 'catalogView=list; path=/; expires=' + date.toUTCString();
        }
    });*/
    if (BX.getCookie('catalogView') == 'bar') {
        $('#listArticles').addClass('articles-grid');
    }
    if (BX.getCookie('catalogView') == 'list') {
        $('#listArticles').removeClass('articles-grid');
    }

    /* если в списке сравнения в хедере нет товаров, то удалять сообщение и добавлять иконку списка */
    const $headerCompareCount = $('#compare .compare-icon__quantity').length;
    if ($headerCompareCount === 0) {
        $('#compare').children().remove();
        $('#compare').html('<svg class="icon compare " aria-hidden="true">\n' +
            '                   <use xlink:href="/local/templates/divers-new/assets/images/required/sprite.svg#compare"></use>\n' +
            '               </svg>\n' +
            '               <span class="compare-icon__quantity">0</span>');
    }

    /* добавление отзыва о товаре пользователем */

    $('#feedback-popup-modal').on('submit', '#goods_reviews', function () {
        const $current = $(this);
        const $modalBody = $('.add-reviews-answer');

        $.ajax({
            type: "POST",
            url: '/local/templates/divers-new/ajax/reviews.php',
            data: $current.serialize(),
            success: function (data) {
                if (data.length > 0) {
                    const json = $.parseJSON(data);
                    $modalBody.children('p').remove();
                    if (json.SUCCESS != false) {
                        $('.feedback__inner').hide();
                        $('#feedback-popup-modal').css({'height': 'auto'});
                        $modalBody.append(`<p>${json.MESSAGE_SUCCESS}</p>`);

                        let reviewsSortBy = $('select[name=sort_reviews]').find(':selected').val();

                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_DETAIL: 'Y', 'SORT_BY_PARAM': reviewsSortBy},
                            success: function (data) {
                                $('#reviewsDetail').replaceWith(data);
                            }
                        });
                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_MOBILE: 'Y'},
                            success: function (data) {
                                $('#reviewsMobile').replaceWith(data);
                            }
                        });
                    } else {
                        $modalBody.append(`<p style="margin-top: 15px;">${json.MESSAGE_ERROR}</p>`);
						grecaptcha.execute(BX.message('CAPTCHA_TOKEN'), { action: 'add_review' }).then(function (token) {
							var recaptchaResponse = document.getElementById('recaptchaResponseReview');
							recaptchaResponse.value = token;
						});
                    }
                }
            },
            error: function () {
                $modalBody.append(`<p style="margin-top: 15px;">При выполнении ajax-запроса возникли ошибки. </p>`);
            }
        });

        return false;
    });

    /* конец добавление отзыва о товаре пользователем */

    /* обработка полезности отзыва */

    $('.benefit-yes').on('click', function () {
        const $current = $(this);
        let reviewId = $current.data('reviewId');

        $.ajax({
            type: 'POST',
            url: '/local/templates/divers-new/ajax/benefit_reviews.php',
            data: {'BENEFIT': true, 'REVIEW_ID': reviewId},
            success: function (data) {
                if (data.length > 0) {
                    const json = $.parseJSON(data);
                    if (json.SUCCESS === true) {
                        let reviewsSortBy = $('select[name=sort_reviews]').find(':selected').val();

                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_DETAIL: 'Y', 'SORT_BY_PARAM': reviewsSortBy},
                            success: function (data) {
                                $('#reviewsDetail').replaceWith(data);
                            }
                        });
                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_MOBILE: 'Y'},
                            success: function (data) {
                                $('#reviewsMobile').replaceWith(data);
                            }
                        });
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) { // Если ошибка, то выводим в консоль
                console.log('Error: ' + errorThrown);
            }
        });

        return false;
    });

    $('.benefit-no').on('click', function () {
        const $current = $(this);
        let reviewId = $current.data('reviewId');

        $.ajax({
            type: 'POST',
            url: '/local/templates/divers-new/ajax/benefit_reviews.php',
            data: {'BENEFIT': false, 'REVIEW_ID': reviewId},
            success: function (data) {
                if (data.length > 0) {
                    const json = $.parseJSON(data);
                    if (json.SUCCESS === true) {
                        let reviewsSortBy = $('select[name=sort_reviews]').find(':selected').val();

                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_DETAIL: 'Y', 'SORT_BY_PARAM': reviewsSortBy},
                            success: function (data) {
                                $('#reviewsDetail').replaceWith(data);
                            }
                        });
                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {RELOAD_REVIEWS_MOBILE: 'Y'},
                            success: function (data) {
                                $('#reviewsMobile').replaceWith(data);
                            }
                        });
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) { // Если ошибка, то выводим в консоль
                console.log('Error: ' + errorThrown);
            }
        });

        return false;
    });

    /* конец обработки полезности отзыва */
});
