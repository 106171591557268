import 'jquery';
import Swiper from 'swiper/bundle';

class SliderCard {
	constructor() {
		this.initialize();
	}

	initialize() {
		const prewiewProductSliderBottom = new Swiper(
			'.preview-product__slider-bottom',
			{
				slidesPerView: 4,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				observer: true,
				observeSlideChildren: true,
				observeParents: true,
			}
		);

		const prewiewProductSliderTop = new Swiper('.preview-product__slider-top', {
			observer: true,
			observeSlideChildren: true,
			observeParents: true,
			/*thumbs: {
				swiper: prewiewProductSliderBottom,
			},*/
			pagination: {
				el: '.preview-product__pagination',
			},
			navigation: {
				nextEl: ".card-product__next-btn",
				prevEl: ".card-product__prev-btn",
			},
		});

		const sliderDetailCard = $('.detail-card-product [data-prewiew-product-slider]');
		const slides = sliderDetailCard.find('.swiper-slide');

		// console.log(sliderPopup);

		if (slides.length <= 1) {
			$('.detail-card-product .card-product__prev-btn, .detail-card-product .card-product__next-btn').css('display', 'none');
		}

		$('.card__view').on('click', function() {
			const el = $(this);
			const mfpSrc = el.attr('data-mfp-src');
			const id = mfpSrc.replace(/[^0-9]/g,"");
			const modal = $(`.popup--preview-product .modal__wrapper[data-id="${id}"]`);
			const slides = modal.find('.swiper-slide');

			if (slides.length <= 1) {
				modal.find('.swiper-button-prev, .swiper-button-next').css('display', 'none');
			}
		});
	}
}

new SliderCard();
