import 'jquery';

class CategoriesMenu {

    constructor() {
        this.initialize();
    }

    initialize() {
        const openCategoriesMenu = function () {
            $('.js-menu-categories').on('click', function () {
                const target = $(this);
                const fade = $('.js-fade-categories');
                const bodyHeight = $('body').innerHeight();

                target.toggleClass('active');
                target.closest('.catalog__top-row').find('.catalog__filter').toggleClass('hide');
                fade.toggleClass('show');
                fade.css({ 'height': `${bodyHeight}` });
            })
        }
        const closeCategoriesMenu = function () {
            $('.js-fade-categories').on('click', function () {
                const target = $(this);
                const filter = $('.filter');

                target.removeClass('show');
                target.closest('.categories').find('.js-menu-categories').removeClass('active');
                filter.removeClass('hide');
            });
        }
        const getCategoriesWidth = function () {
            const ww = $(window).width();

            if (ww < 1024) {
                const categoriesWidth = $('.categories__inner').innerWidth() - 220;
                const categoriesContent = $('.categories__content');

                categoriesContent.css({ 'width': categoriesWidth });
            }

            if (ww < 1024) {
                $(window).on('resize', function () {
                    const categoriesWidth = $('.categories__inner').innerWidth() - 220;
                    const categoriesContent = $('.categories__content');

                    categoriesContent.css({ 'width': categoriesWidth });
                });
            }


        }


        openCategoriesMenu();
        closeCategoriesMenu();
        getCategoriesWidth();
    }
}

new CategoriesMenu();


