import 'jquery';
import Swiper from 'swiper/bundle';

class ArticleTags {
	constructor() {
		this.initialize();
	}

	initialize() {
		new Swiper('[data-slider-article-tags]', {
			slidesPerView: 'auto',
			spaceBetween: 20,
		});
	}
}

new ArticleTags();
