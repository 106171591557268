import 'jquery';
import Swiper from 'swiper/bundle';
import { SliderTags } from '../slider-tags/slider-tags.js';
import { ToggleGrid } from '../toggle-grid/toggle-grid.js';

export class SliderTicker {
	constructor() {
		this.initialize();
	}

	initialize() {
		let isLoop = false;
		const slidesCount = $('[data-blog-slider]').find('.blog__slider-item').length;
		if ($(window).width() > 1023) {
			if (slidesCount >= 5) isLoop = true
		} else {
			if (slidesCount >= 4) isLoop = true
		}

		const blogSlider = new Swiper('[data-blog-slider]', {
			slidesPerView: 2.2,
			centeredSlides: true,
			spaceBetween: 20,
			loop: isLoop,
			breakpoints: {
				1024: {
					slidesPerView: 4.2,
					spaceBetween: 25
				},
				1280: {
					slidesPerView: 3.7,
					spaceBetween: 30
				},
				1440: {
					slidesPerView: 2.8,
					spaceBetween: 40
				}
			}
		});

		if (!isLoop) {
			if ($(window).width() > 1023) {
				if (slidesCount === 3) {
					blogSlider.slideTo(1);
				} else if (slidesCount === 4) {
					blogSlider.slideTo(2);
				}
			} else {
				if (slidesCount > 2) blogSlider.slideTo(1);
			}
		}
	}
}

new SliderTicker();

$(document).on("click", '.tags-link', function () {
	var str = $(this).data("val");
	/* var str2 = "";
	var cur_tags = [];
	cur_tags.push(str);
	$(".tags-link.active").each(function (){
		if($(this).data("val") !== "" && cur_tags.indexOf($(this).data("val")) === -1)
		{
			cur_tags.push($(this).data("val"));
			str2 += "," + $(this).data("val");
		}
	});*/
	var param = str;
	if(location.pathname.indexOf('/blog/') !== -1)
	{
		if ($(this).closest('.swiper-slide').hasClass('active')) {
			window.location.href = location.pathname;
		} else {
			window.location.href = location.pathname + '?tags=' + param;
		}
	}
	else {
		if ($(this).closest('.swiper-slide').hasClass('active')) {
			param = '';
		}
		$.ajax({
			url: location.pathname + '?tags=' + param,
			dataType: 'html',
			success: function (data) {
				$(".blog .blog__inner").html(data);

				new SliderTicker();
				new SliderTags();
				new ToggleGrid();
			}
		});
	}
});

