import 'jquery';
import Swiper from 'swiper/bundle';

class SliderGallery {
	constructor(sliderTop, sliderBottom) {
		this.sliderTop = sliderTop;
		this.sliderBottom = sliderBottom;
	}

	initialize() {
		const sliderGalleryBottom = new Swiper(this.sliderBottom[0], {
			slidesPerView: 4,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			spaceBetween: 10,
		});

		new Swiper(this.sliderTop[0], {
			spaceBetween: 10,
			thumbs: {
				swiper: sliderGalleryBottom,
			},
			pagination: {
				el: '.preview-product__pagination',
			},
			navigation: {
				nextEl: '.card-product__next-btn',
				prevEl: '.card-product__prev-btn',
			},
		});
	}
}

$(document).ready(function () {
	let slidersGallery = [];

	if ($('[data-slider-gallery-top]').length > 0) {
		$('[data-slider-gallery-top]').each(function (i) {
			let sliderTop = $(this);
			let sliderBottom = $(this).parent().find('[data-slider-gallery-bottom]');

			slidersGallery[i] = new SliderGallery(sliderTop, sliderBottom);
			slidersGallery[i].initialize();
		});
	}
});
