import 'jquery';

class MenuCarousel {

	constructor() {
		this.initialize();
	}

	initialize() {
		$('[data-menu-box]').each(function (){
			if(!$(this).hasClass('right')){
				if($(this).find('[data-menu]').width() > $(this).width()){
					const distinction = $(this).find('[data-menu]').width() - $(this).width()
					$(this).addClass('right')
					$(this).append('<span class="menu-carousel__menu-btn menu-carousel__menu-btn-right" data-menu-btn-right></span><span class="menu-carousel__menu-btn menu-carousel__menu-btn-left" data-menu-btn-left></span>')
					const that = $(this)
					$(this).find('[data-menu-btn-right]').on('click', function (){
						that.find('[data-menu]').css('left', -distinction)
						that.removeClass('right')
						that.addClass('left')
					});
					$(this).find('[data-menu-btn-left]').on('click', function (){
						that.find('[data-menu]').css('left', 0)
						that.removeClass('left')
						that.addClass('right')
					})
				}
			}
		})
	}
}

new MenuCarousel();


