import 'jquery';

class catalog {

    constructor() {
        this.initialize();
    }

    initialize() {

        const menuControl = function() {
            const catalogButton = $('.js-catalog-toggle');

            catalogButton.on('click', function (evt) {
                evt.preventDefault();
    
                const body = $('body');
                const el = $(this);
                const catalog = $('[data-catalog]');
                const fixedMobileMenu = $('.fixed-mobile-menu');

                fixedMobileMenu.toggleClass('z-index-26');
                el.toggleClass('active');
                body.toggleClass('no-scroll');
                catalog.toggleClass('active');
            });
        }
        
        const navigationControl = function () {
            const buttonLink = $('[data-menu-drop]');
            const buttonBack = $('[data-menu-back]');
            const buttonDropList = $('[data-menu-drop-list]');

            buttonLink.each(function() {
                if ($(this).next().length === 0) {
                    $(this).removeAttr('data-menu-drop');
                }

                if ($(this).next().length != 0) {
                    $(this).removeAttr('href');
                }
            });

            setTimeout(function(){
                buttonLink.on('click', function (evt) {
                    if (buttonLink.attr('data-menu-drop')) {
                        evt.preventDefault();
                    }
    
                    const el = $(this);
                    const dropListInner = el.parent().children('[data-menu-drop-inner]');
    
                    dropListInner.addClass('open');
                });
    
                buttonBack.on('click', function () {
                    const el = $(this);
                    const dropListInner = el.closest('li').children('[data-menu-drop-inner]');
    
                    dropListInner.removeClass('open');
                });
    
                buttonDropList.on('click', function () {
                    const el = $(this);
                    const dropListInner = el.parent().children('[data-menu-drop-list-inner]');
    
                    el.toggleClass('open');
                    dropListInner.toggleClass('open');
                });
            }, 100);
        }

        menuControl();
        navigationControl();
    }
}

new catalog();