import 'jquery';
import Swiper from 'swiper/bundle';

class SliderCard {
	constructor() {
		this.initialize();
	}

	initialize() {

		const sliderCardBottom = new Swiper('[data-slider-card-bottom]', {
			slidesPerView: 4,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			observer: true
		});

		const sliderCardTop = new Swiper('[data-slider-card]', {
			slidesPerView: 1,
			observer: true,
			thumbs: {
				swiper: sliderCardBottom
			},
			pagination: {
				el: '.slider-card__pagination',
				clickable: true
			}
		});
		// $('.color-picker__label').click(function (){
		// 	let el = $(this).children().first()
		// 	let sliderItem = $(".card-product__slider.slider-card div[data-color="+el.attr('data-color').split('-').join('')+"]")
		// 	let index = sliderItem.attr('class').split('-')[1]
		// 	const swiper = document.querySelector('[data-slider-card]').swiper;
		// 	swiper.slideTo(index);
		// });
		// sliderCardTop.controller.control = sliderCardBottom;
    	// sliderCardBottom.controller.control = sliderCardTop;
	}
}

new SliderCard();
