import 'jquery';
import Swiper from 'swiper/bundle';

class SliderBrands {
	constructor() {
		this.initialize();
	}

	initialize() {
		// let elemsBrandSliderTop = $('.brands__sldier-top .swiper-slide').length;
		// let elemsBrandSliderBottom = $('.brands__sldier-bottom .swiper-slide').length;

		// const brandsSliderTop = new Swiper('[data-brand-slider-top]', {
		// 	slidesPerView: 'auto',
		// 	loopAdditionalSlides: elemsBrandSliderTop,
		// 	loop: true,
		// 	allowTouchMove: false,
		// 	speed: 3000,
		// 	autoplay: {
		// 		delay: 0,
		// 		disableOnInteraction: false
		// 	}
		// });

		// const brandsSliderBottom = new Swiper('[data-brand-slider-bottom]', {
		// 	slidesPerView: 'auto',
		// 	loopAdditionalSlides: elemsBrandSliderBottom,
		// 	loop: true,
		// 	allowTouchMove: false,
		// 	speed: 3000,
		// 	autoplay: {
		// 		delay: 0,
		// 		disableOnInteraction: false,
		// 		reverseDirection: true
		// 	}
		// });

		const sliderBrands = new Swiper('[data-brands-slider]', {
			slidesPerView: 3,
			speed: 3000,
			spaceBetween: 20,
			loop: true,
			freeMode: true,
			autoplay: {
				delay: 0,
				disableOnInteraction: false
			},
			breakpoints: {
				768: {
					slidesPerView: 4,
				},
				1280: {
					slidesPerView: 5,
				}
			}
		});


		$('.brands__inner').on('mouseenter', function() {
			sliderBrands.autoplay.stop();
		});

		$('.brands__inner').on('mouseleave', function() {
			sliderBrands.autoplay.start();
		});
	}
}

new SliderBrands();
