import 'jquery';

export class ToggleGrid {

    constructor() {
        this.initialize();
    }

    initialize() {
        const toggleGridBlog = function () {
            $('.js-toggle-grid').on('click', function (evt) {
                evt.preventDefault();

                const el = $(this);
                const getAttrBtn = el.attr('data');
                const listAticle = $('.article__list');

                if (getAttrBtn === 'grid') {
                    listAticle.addClass('articles-grid');
                }

                if (getAttrBtn === 'list') {
                    listAticle.removeClass('articles-grid');
                }
            });
        }

        toggleGridBlog();
    }
}

new ToggleGrid();


