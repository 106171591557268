import $ from 'jquery';

class ShortModalWindow {
  constructor(elem) {
    this.modal = elem;
    this.dragElem = this.modal.find('[data-drag-short-modal]');
    this.close = this.modal.find('[data-close-short-modal]');

    this.drag = false;
    this.rootPos = null;
  }

  initialize() {
    const that = this;
    const body = $('body');
    const html = $('html');
    const shortModal = $('[data-short-modal]');
    const shortModalWrapper = $('[data-short-modal] [data-root]');
    const btnOpenShortModal = $('[data-button-short-modal]');
    const root = $(this.modal).find('[data-root]');

    this.rootPos = root.offset().top;

    btnOpenShortModal.on('click', function (e) {
      const id = $(this).attr('data-short-modal-id');
      const modal = $(`[data-short-modal="${id}"]`);

      setTimeout(function () {
        modal.find('[data-root]').addClass('active');
      }, 200);

      modal.removeClass('hide');
      body.addClass('no-scroll');
      html.addClass('no-scroll');
    });

    shortModal.on('click', function () {
      const el = $(this);
      const modal = el.find('[data-root]');

      modal.removeClass('active');

      setTimeout(function () {
        el.addClass('hide');
      }, 200);

      $('body').removeClass('no-scroll');
      $('html').removeClass('no-scroll');
    });

    this.close.on('click', function () {
      that.modal.removeClass('active');
      that.drag = false;
      that.modal.removeClass('no-transition');

      setTimeout(() => {
        const el = $(this);
        const root = el.closest('[data-root]');
        const shortModal = el.closest('[data-short-modal]');

        root.removeClass('active');
        shortModal.addClass('hide');
      }, 200);

      $('body').removeClass('no-scroll');
      $('html').removeClass('no-scroll');
    });

    this.dragElem.on('touchstart', function (e) {
      that.drag = true;
      that.rootPos = e.changedTouches[0].clientY;
      that.modal.addClass('no-transition');
    });

    $(window).on('touchmove', function (e) {
      if (that.drag) {
        if (e.changedTouches[0].clientY - that.rootPos < 0) {
          root.css({ transform: `translateY(0px)` });
        } else {
          root.css({ transform: `translateY(${e.changedTouches[0].clientY - that.rootPos}px)` });
        }
      } else {
        root.css({ transform: `translateY(0px)` });
      }
    });

    this.dragElem.on('touchend', function (e) {
      that.drag = false;

      if (e.changedTouches[0].clientY > that.rootPos + 100) {
        that.modal.removeClass('active');
        that.drag = false;
        that.modal.removeClass('no-transition');
        setTimeout(function () {
          shortModal.addClass('hide');
        }, 100);

        setTimeout(() => {
          root.css({ transform: `translateY(${0}px)` });
        }, 500);
        $('body').removeClass('no-scroll');
        $('html').removeClass('no-scroll');
      } else {
        root.css({ transform: `translateY(${0}px)` });
      }
    });

    shortModalWrapper.on('click', function (evt) {
      evt.stopPropagation();
    });
  }
}

// $(window).on('load', function () {
//   let shortmodals = [];

//   if ($('[data-short-modal]').length > 0) {
//     $('[data-short-modal]').each(function (i) {
//       shortmodals[i] = new ShortModalWindow($(this));
//       shortmodals[i].initialize();
//     });
//   }
// });

$(window).on('scroll', function () {
  let shortmodals = [];

  if ($('[data-short-modal]').length > 0) {
    $('[data-short-modal]').each(function (i) {
      shortmodals[i] = new ShortModalWindow($(this));
      shortmodals[i].initialize();
    });
  }
});
