import 'jquery';

class menuMobile {

    constructor() {
        this.initialize();
    }

    initialize() {
        const menuMobileControl = function () {
            const burger = $('.js-burger-toggle');
            const area = $('.menu-mobile');
            const areaInner = $('.menu-mobile > *');

            burger.on('click', function () {
                const el = $(this);
                const body = $('body');
                const menuMobile = $('[data-main-menu]');
                const fixedMobileMenu = $('.fixed-mobile-menu');

                el.toggleClass('open');
                body.toggleClass('no-scroll');
                menuMobile.toggleClass('active');
                fixedMobileMenu.toggleClass('z-index-14');
            });

            area.on('click', function () {
                const el = $(this);
                const body = $('body');
                const fixedMobileMenu = $('.fixed-mobile-menu');
                const burger = $('.burger-menu');
                const burgerIcon = $('.burger-menu span');

                burger.removeClass('open');
                burgerIcon.removeClass('active');
                el.removeClass('active');
                body.removeClass('no-scroll');
                fixedMobileMenu.removeClass('z-index-14');
                fixedMobileMenu.removeClass('z-index-26');
            });

            areaInner.on('click', function (evt) {
                evt.stopPropagation();
            });
        }

        const scrollBody = function () {
            let scrollPosition = 0;

            $(window).on('scroll', function () {
                let st = $(this).scrollTop();
                const header = $('header');
                const headerHeight = header.height();

                if (st >= scrollPosition) {
                    header.removeClass('fixed');
                } else {
                    header.addClass('fixed');
                    header.removeClass('header-hidden');
                }

                if (st < headerHeight) {
                    header.removeClass('header-hidden');
                } else if (st > headerHeight) {
                    header.addClass('header-hidden');
                }

                scrollPosition = st;
            });
        }

        const chooseCity = function () {
            const buttonChooseCity = $('.js-choose-city');
            const city = $('[data-drop-list-citys] ul li a');

            buttonChooseCity.on('click', function () {
                const el = $(this);
                const dropListCitys = el.closest('.city-selection').children('[data-drop-list-citys]');

                dropListCitys.addClass('open');
            });

            city.on('click', function () {
                const el = $(this);
                const nameCity = el.text();
                const citySelection = el.closest('.city-selection');
                const fieldSelectedCity = citySelection.find('.city-selection__city-name');
                const dropListCitys = citySelection.find('[data-drop-list-citys]');

                fieldSelectedCity.text(nameCity);
                dropListCitys.removeClass('open');
            });
        }

        const animationBurger = function () {
            $('.js-burger-toggle').on('click', function () {
                $('.js-burger-toggle span').toggleClass('active');
            });
        }

        animationBurger();
        menuMobileControl();
        scrollBody();
        chooseCity();
    }
}

new menuMobile();
