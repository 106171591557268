import 'jquery';

class Basket {

    constructor() {
        this.initialize();
    }

    initialize() {
        const elemSelection = function () {
            $('.js-toggle-list').on('click', function () {
                const el = $(this);

                el.toggleClass('active');
                el.next().toggleClass('active');
            });

            $('.js-toggle-list-wrap label input[type="radio"]').on('change', function () {
                const el = $(this);
                const colorList = el.closest('[data-list-elements]');
                const arrow = el.closest('.js-toggle-list-wrap').children('.js-toggle-list');

                colorList.removeClass('active');
                arrow.removeClass('active');
            });

            $(document).on('click', function (evt) {
                if (!$(evt.target).closest('.js-toggle-list-wrap').length) {
                    $('.js-toggle-list').removeClass('active');
                    $('.js-toggle-list').next().removeClass('active');
                }
            });
        }

        const editOptions = function () {
            // $('.js-edit-options').on('click', function () {
            //     const el = $(this);

            //     el.parent().css({'transform': 'translate3d(-60px, 0, 0)'});
            // });

            const productBasket = $('.basket__product');

            document.addEventListener('touchstart', handleTouchStart, false);
            document.addEventListener('touchmove', handleTouchMove, false);

            let x1 = null;
            let y1 = null;

            function handleTouchStart(evt) {
                const firstTouch = evt.touches[0];
                x1 = firstTouch.clientX;
                y1 = firstTouch.clientY;
            };

            function handleTouchMove(evt) {
                if (!x1 || !y1) {
                    return false;
                }

                let x2 = evt.touches[0].clientX;
                let y2 = evt.touches[0].clientY;
                let xDiff = x2 - x1;
                let yDiff = y2 - y1;

                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    if (xDiff > 0) {
                        console.log('right');

                        productBasket.css({'transform': 'translate3d(0, 0, 0)'});
                    } else {
                        console.log('left');

                        productBasket.css({'transform': 'translate3d(-60px, 0, 0)'});
                    }
                } else {
                    if (yDiff > 0) {
                        console.log('down');
                    } else {
                        console.log('top');
                    }
                }

                x1 = null;
                y1 = null;
            };
        }

        // const productQuantity = function () {

        //     $(document).on('click', '.js-quantity-counter button', function (e) {
        //         e.preventDefault();
        //         let button = $(this);
        //         let input = $(this).siblings('.basket__quantity-value');
        //         let dataButton = button.attr('data-quantity');
        //         let val = parseInt(input.val());

        //         if ($(this).data('quantity') === 'plus') {
        //             val++;
        //         }
        //     });
        // }

        elemSelection();
        // editOptions();
        // productQuantity();
    }
}

new Basket();