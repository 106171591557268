import 'jquery';
class Search {

    constructor() {
        this.initialize();
    }

    initialize() {
        const searchButton = $('.js-button-search');
        const searchInner = $('.js-button-search > .search-input');
        const searchFade = $('.js-search-fade');
        const inputWrap = $('.search-input');
        const burger = $('.burger-menu');
        const input = $('.search-input .search-input__input');
        const dropList = $('.search-input .search-input__drop-list');

        searchButton.on('click', function () {
            burger.toggleClass('disabled')
            inputWrap.addClass('open');
            searchFade.addClass('show');
        });

        searchFade.on('click', function () {
            $(this).removeClass('show');
            burger.removeClass('disabled');
            inputWrap.removeClass('open');
            input.val('');
        });

        searchInner.on('click', function (evt) {
            evt.stopPropagation();
        });

        input.on('input', function() {
            dropList.removeClass('hide');

            if ($(this).val() === '') {
                dropList.addClass('hide');
            }
        });
    }
}

new Search();


