class Subscribe {
	constructor() {
		this.init();
	}

	init() {
		const btnSubscribe = document.querySelector('[data-btn-subscribe]');

		if(!btnSubscribe) return false;

		btnSubscribe.addEventListener('click', function (evt) {
			evt.preventDefault();

			const notice = document.querySelector('.offer-banner-big__notice');

			notice.style.display = 'block';

			setTimeout(function () {
				notice.style.display = 'none';
			}, 3500);
		});
	}
}

new Subscribe();
