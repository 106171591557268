import 'jquery';

class regUser {
	constructor() {
		this.initialize();
	}

	initialize() {
		const btnRegNewUser = $('[data-reg-new-user]');
		btnRegNewUser.on('click', function () {
			const windowOne = $('.personal-area-modal [data-window-one]');
			const windowTwo = $('.personal-area-modal [data-window-two]');
			const mpfContent = $('.mfp-content');
			mpfContent.addClass('mfp-prevent-close');
			windowOne.css('display', 'none');
			windowTwo.css('display', 'block');

			closePopup();
		});

		function closePopup() {
			const btnClosePopup = $('.mfp-close');
			const windowOne = $('.personal-area-modal [data-window-one]');
			const windowTwo = $('.personal-area-modal [data-window-two]');

			btnClosePopup.on('click', function () {
				windowOne.css('display', 'block');
				windowTwo.css('display', 'none');
			});
		}

		function closeBlackArea() {
			const btnClosePopup = $('.mfp-close-btn-in');
			const windowOne = $('.personal-area-modal [data-window-one]');
			const windowTwo = $('.personal-area-modal [data-window-two]');

			btnClosePopup.on('click', function () {
				windowOne.css('display', 'block');
				windowTwo.css('display', 'none');
			});
		}

		$('.personal-area-modal [data-window-two]').on('click', function (evt) {
			evt.stopPropagation();
		});

		$('.personal-area-modal [data-window-one]').on('click', function (evt) {
			evt.stopPropagation();
		});
	}
}

new regUser();
