import 'jquery';

class Ordering {

    constructor() {
        this.initialize();
    }

    initialize() {

        const inputDropList = function () {

            $('.js-input-droplist').on('keyup', function () {
                const value = $(this).val();

                if (value.length != '') {
                    $('.js-droplist').addClass('show');
                } else {
                    $('.js-droplist').removeClass('show');
                }
            });

            $('.js-input-droplist').on('blur', function () {
                $('.js-droplist').removeClass('show');
            });
        }

        const navigation = function () {
            const btnNext = $('[data-nav="next"]');
            const btnPrev = $('[data-nav="prev"]');

            btnNext.on('click', function () {
                const el = $(this);
                const thisStep = el.closest("[data-step]");
                const thisStepNumber = parseInt(thisStep.attr('data-step'));
                const nextCard = thisStepNumber + 1;

                thisStep.addClass('hidden');

                $(`[data-step="${nextCard}"]`).removeClass('hidden');
            });

            btnPrev.on('click', function(){
                const el = $(this);
                const thisStep = el.closest("[data-step]");
                const thisStepNumber = parseInt(thisStep.attr('data-step'));
                const nextCard = thisStepNumber - 1;

                thisStep.addClass('hidden');

                $(`[data-step="${nextCard}"]`).removeClass('hidden');
            });
        }

        navigation();
        inputDropList();
    }
}

new Ordering();
